function isAdminPanelOpen() {
    return $('#admin_tools_panel').is(':visible')
}
function show_admin_tools_panel() {
    $('#admin_tools_panel').show();
    $('#masquerade_switcher').show();
}
function close_admin_tools_panel() {
    $('#admin_tools_panel').hide();
}

$(document).ready(function(){
    // if the 'internal tools' feature is absent, shift the panel over a bit to compensate
    if ($('#internal_tools_link').length === 0){
        $('#tabhead #admin_tools_panel').css('left', '-46px');
    }

    // populate masquerade switcher
    if ( document.getElementById('masquerade_switcher_users') ) {
        var select = document.getElementById('masquerade_switcher_users');
        var username;
        if ( document.querySelector('.pl-navbar') ) {
            username = document.querySelector('.pl-navbar').getAttribute('data-user');
        } else if ( document.getElementById('vendor_data') ) {
            username = document.getElementById('vendor_data').getAttribute('data-user');
        }

        // first, determine who the "root" user is
        var rootUser = ''
        var getRootUser = $.ajax({
            type: 'GET',
            url: "/accounts/v1/me/session/",
            success: function(response) {
                rootUser = response.Results.original_username;
            }, error: function(err, status, error) {
                console.log(err.responseText);
            }
        });

        getRootUser.done(function(){
            if (!rootUser) {
                rootUser = username;
            }
            
            $.ajax({
                type: 'GET',
                url: "/accounts/v1/masquerade/?q=admin_user/"+ rootUser,
                success: function(response) {
                    /* Only show switch user button if current (root) user has masquerade accounts */
                    if ( response.Results.length > 0 ) {
                        // only initialize display of the panel if the header is detected.
                        // prevents panel from showing up on admin site.
                        personal_dropdown = $('ul[aria-labelledby="navbarDropdown2"]');
                        if (personal_dropdown){
                            $('#admin_tools_panel_short').insertAfter(personal_dropdown.find('li')[0]);
                        }
                        $('#admin_tools_panel_short').show();
                    }

                    select.innerHTML += '<option value="'+ rootUser +'">'+ rootUser +' (original)</option>';
                    var currentActiveUser;
                    var getCurrentActiveUser = $.ajax({
                        type: 'GET',
                        url: "/accounts/v1/me/",
                        success: function(response) {
                            currentActiveUser = response.Results.username
                        },
                        error: function(err, status, error) {
                            console.log(err.responseText);
                        }
                    });
                    getCurrentActiveUser.done(function(){
                        for (user in response.Results) {
                            var isCurrent = ''
                            if (currentActiveUser === response.Results[user].masquerade_user ) {
                                isCurrent = 'selected'
                            }
                            select.innerHTML += '<option value="'+ response.Results[user].masquerade_user +'" '+ isCurrent +'>'+ response.Results[user].masquerade_user +'</option>';
                        }
                    })
                },
                error: function(err, status, error) {
                    console.log(err.responseText);
                }
            });
        })
    }

    // indicate that user is 'logged in as' if they are logging into another user
    if ($('#masquerade_switcher_users').val() !== '') {
        $('<span style="font-weight: 500; vertical-align: middle;">(logged in as) </span>').insertBefore('.navbarDropdown2');
    }

    // handle click events
    $('#admin_tools_panel_short').click(function(event){
        if (isAdminPanelOpen()) {
            close_admin_tools_panel();
        }
        else {
            show_admin_tools_panel();
            event.stopPropagation();
            $('html').on('click', function(event2) {
                if (!$(event2.target).closest('#admin_tools_panel').length &&
                !$(event2.target).is('#admin_tools_panel')) {
                    close_admin_tools_panel();
                }
            });
        }
    });

    // handle user switching
    $('#masquerade_switcher_users').change(function(e){
        var masquerade = e.target.options[e.target.selectedIndex].value;
        $.ajax({
            type: 'POST',
            url: "/accounts/v1/masquerade/"+ masquerade +"/",
            success: function(response) {
                console.log(response)
                location.href = location.origin;
            },
            error: function(err, status, error) {
                console.log(err.responseText);
            }
        });
    });
});
