/* Company and brand switcher controls */
const toggleGroupSwitcher = (el) => {
    const openMenu = document.querySelector('.group-switcher.showing');

    // If a different switcher menu is open, close it first.
    if (el != openMenu) {
        openMenu?.classList.remove('showing');
    }
    el.classList.add('showing');
    
    const _closeMenu = (e) => {
        if (el != e.target) {
            el.classList.remove('showing');
            document.removeEventListener('click', _closeMenu);
        }
    }
    document.addEventListener('click', (e) => {
        _closeMenu(e);
    });
}

// Interfaces with setActiveGroup in common.js from UI
const changeGroup = (el) => {
    const group_id = el.value;
    const nextUrl = el.querySelectorAll('option')[el.selectedIndex]?.getAttribute('data-next-url') || '/pages/browse/list';
    PerformLine.setActiveGroup(group_id, nextUrl);
}

const switchers = document.querySelectorAll('.group-switcher');
switchers.forEach(el => {
    el.addEventListener('click', (e) => {
        toggleGroupSwitcher(e.target);
    });
    el.querySelector('select').addEventListener('click', (e) => {
        e.stopPropagation(); // don't trigger toggleGroupSwitcher again
    })
    el.querySelector('select').addEventListener('change', (e) => {
        changeGroup(e.target);
    })
});