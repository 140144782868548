// List of jQuery selectors that will trigger the load animation
var elements = "input:submit, .load, .bigbutton, #exportlink, #Login_CTA, .ui-button-text";
// jQuery selectors for elements that initiate some sort of download
var download_buttons = new Array("Download", "Export Pages", "Export", "exportlink");
// Elements that may fit one of the broad selectors in 'elements' but should not have a load animation
var exceptions = new Array(
	"Rescore and Make Observations",
	"Undo Changes to Term Order",
	"edit_tag_class",
	"Cancel",
	"cancel",
	"OK",
	"ok",
	"Ok",
	"low",
	"medium",
	"high",
	'rule_change_report',
	"mark_pages",
	"no_loading_animation",
	"saved_filter_submit",
	"kraken-submit"
);

var load_animation = function(obj, e) {
	// e.preventDefault();
	// Check if 'obj' is in the 'exceptions' array
	var execute = false;
	for (var i=0; i<exceptions.length; i++) {
		if (obj.val() == exceptions[i] ||
			obj.attr("id") == exceptions[i] ||
            (obj.attr("class") && obj.attr("class").split(' ').indexOf(exceptions[i]) > -1) ||
			obj.text() == exceptions[i]) {
			return;
		} else if (i + 1 == exceptions.length) {
			execute = true;
		}
	}

	// Check if ctrl key is pressed
	if (window.event) {
		var keyPress = window.event.ctrlKey;
	} else {
		var keyPress = e.ctrlKey === 1;
	}

	// Don't use animation if ctrl+click (open in new tab)
	if (execute && !keyPress) {
	    $(".load_screen").css({"visibility":"visible", "z-index":"100000"});
	    $(".load_screen").fadeTo("slow", 0.3, function() {
	    	// Check if download button
	    	for (var i=0; i<download_buttons.length; i++) {
	    		if (obj.val() == download_buttons[i] ||
	    			obj.attr("id") == download_buttons[i] ||
	    			obj.attr("class") == download_buttons[i]) {
	    			setTimeout(hide_load, 4000);
	    		}
	    	}
	    });
	}
}

var hide_load = function() {
	$(".load_screen").fadeTo("slow", 0, function() {
		$(this).css({"visibility":"hidden", "z-index":"-1"});
	});
}

$(document).ready(function() {
	$(elements).on("click", function(e) {
		var target = $(this);
		// check form validation before showing loader
		var thisForm = target.parent('form').get(0);
		if ( thisForm ) {
			var requiredValidation = true;

			for (var i = 0; thisForm.querySelectorAll('[required]').length > i; i++) {
				var thisField = thisForm.querySelectorAll('[required]')[i];
				if (thisField.type === 'radio' || thisField.type === 'checkbox') {
					if (!thisField.checked) {
						requiredValidation = false;
						break;
					}
				} else {
					if (!thisField.value) {
						requiredValidation = false;
						break;
					}
				}
			}
			if ( requiredValidation ) {
				if (target.get(0) == $("input:submit").get(0)) {
					if (target.val() == "Submit" || target.val() == "submit") {
						setTimeout(function() {
							load_animation(target, e);
						}, 500);
					}
				} else {
					setTimeout(function() {
						load_animation(target, e);
					}, 500);
				}
			}
		}
	});
});
