/* Company and brand switcher controls */
const toggleGroupSwitcher = (el) => {
    const openMenu = document.querySelector('.group-switcher.showing');

    // If a different switcher menu is open, close it first.
    if (el != openMenu) {
        openMenu?.classList.remove('showing');
    }
    el.classList.add('showing');
    
    const _closeMenu = (e) => {
        if (el != e.target) {
            el.classList.remove('showing');
            document.removeEventListener('click', _closeMenu);
        }
    }
    document.addEventListener('click', (e) => {
        _closeMenu(e);
    });
}

const switchCompany = (el) => {
    const companyGroupId = el.getAttribute('data-company-id');
    window.location.href = `/accounts/v1/me/activate-group/?group=${companyGroupId.toString()}&next=/&errnext=/login`;
}

const companySelectOptions = document.querySelectorAll('#pw-company-select');
companySelectOptions.forEach(el => {
    el.addEventListener('click', (e) => {
        switchCompany(el)
    });
});

