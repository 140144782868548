$(document).ready(function() {
    $('.help-text-data').each(function(index, elem) {
        var target_class = '.' + $(elem).data('target-class');
        var content = $(elem).data('content');
        var use_child = $(elem).data('use-child');
        var use_icon = $(elem).data('use-icon');

        if (use_child > 0) {
            if ($(target_class).children().length > 0) {
                $(target_class).children().slice(use_child - 1, use_child).tooltipster({
                    contentAsHTML: true,
                    content: $("<p>" + content + "</p>".replace(/\n/g, '<br/>')),
                    delay: use_icon ? 100 : 600,
                    trigger: use_icon ? "click" : "hover",
                    theme: "tooltipster-performline-theme",
                    maxWidth: 400,
                    multiple: true,
                });
            } else {
                $(target_class).tooltipster({
                    contentAsHTML: true,
                    content: $("<p>" + content + "</p>".replace(/\n/g, '<br/>')),
                    delay: use_icon ? 100 : 600,
                    trigger: use_icon ? "click" : "hover",
                    theme: "tooltipster-performline-theme",
                    maxWidth: 400,
                    multiple: true,
                });
            }
        } else {
            $(target_class).tooltipster({
                contentAsHTML: true,
                content: $("<p>" + content + "</p>".replace(/\n/g, '<br/>')),
                delay: use_icon ? 100 : 600,
                trigger: use_icon ? "click" : "hover",
                theme: "tooltipster-performline-theme",
                maxWidth: 400,
                multiple: true,
            });
        }
    });
});
