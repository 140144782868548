
/*! PERFORMLINE SCRIPTS */

// Collapses nav if cookie is set
if ( document.cookie.indexOf('PLnav=collapsed') > -1 ) {
    document.body.classList.add('pl-collapsed-nav');
}

// Removes focus styles on click only if we have JS
// Tab focus styles activate on tab, so accessibility is maintained
document.body.classList.add('no-tab');
// Removes .loading class from body, showing the body content
document.body.classList.remove('pl-loading'); 
