/* Helper Functions */
// Check for a class
function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}
// Find parent element w/ certain class, from https://stackoverflow.com/a/22119674/5683028
function findParentClass(element, className) {
    while ((element = element.parentElement) && !element.classList.contains(className));
    return element;
}


/* PL Navigation Functionality */
// Navigation collapse
function setNavCookie() {
    if (hasClass(document.body, 'pl-collapsed-nav')) {
        document.cookie = "PLnav=expanded;path=/";
        document.body.classList.remove('pl-collapsed-nav');
    } else {
        document.cookie = "PLnav=collapsed;path=/";
        document.body.classList.add('pl-collapsed-nav');
    }
}

function toggleMobileNav() {
    var nav = document.querySelector('.pl-navbar')
    if (hasClass(nav, 'expanded')) {
        nav.classList.remove('expanded')
    } else {
        nav.classList.add('expanded')
    }
}

$(document).ready(function () {

    // Set subnav top offsets
    // (this is because of a dumb css spec that says you can't have different overflow-x and -y values
    // if one of them is "visible" and the other isn't. so to make the nav -y scrollable AND show subnavs,
    // I'm making subnavs position:absolute and setting the height with this.)
    function setSubnavPositions() {
        for (var pl_subnav = 0; document.querySelectorAll('.pl-sub-nav').length > pl_subnav; pl_subnav++) {
            var subnav = document.querySelectorAll('.pl-sub-nav')[pl_subnav];
            var subnavBottom = (findParentClass(subnav, 'pl-has-subnav').offsetTop) + (subnav.offsetHeight);
            if (subnavBottom > window.innerHeight) {
                // if subnav menu extends below viewport, anchor it to bottom of nav item instead of top
                subnav.style.bottom = (window.innerHeight - findParentClass(subnav, 'pl-has-subnav').offsetTop) - (findParentClass(subnav, 'pl-has-subnav').offsetHeight) + 'px';
                subnav.style.top = 'initial';
            } else {
                subnav.style.top = findParentClass(subnav, 'pl-has-subnav').offsetTop + 'px';
            }
        }
    }

    setSubnavPositions();

    /* Event handlers */
    if (document.getElementById('trigger_nav_toggle')) {
        document.getElementById('trigger_nav_toggle').addEventListener("click", function () {
            toggleMobileNav()
        });
    }

    if (document.getElementById('trigger_nav_cookie')) {
        document.getElementById('trigger_nav_cookie').addEventListener("click", function () {
            setNavCookie()
        });
    }


    if (document.getElementById('trigger_company_change')) {
        document.getElementById('trigger_company_change').addEventListener("change", function (e) {
            PerformLine.setActiveGroup(e.target.options[e.target.selectedIndex].value)
        });
    }

    if (document.getElementById('trigger_brand_change')) {
        document.getElementById('trigger_brand_change').addEventListener("change", function (e) {
            PerformLine.setActiveGroup(e.target.options[e.target.selectedIndex].value, e.target.options[e.target.selectedIndex].getAttribute('data-next-url'))
        });
    }

    // Reposition subnavs on window resize
    window.addEventListener('resize', setSubnavPositions);

});